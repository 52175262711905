<script lang="ts" setup>
import { navigateTo } from '#app/composables/router';
import { reactive } from 'vue';
import { getUserData } from '~/utils/getUser';
import { setToken } from '~/utils/tokens';
import { createToken } from '~/utils/userClient';

definePageMeta({
  middleware: 'non-connected',
});

const config = useRuntimeConfig();

const form = reactive({
  data: {
    email: '',
    password: '',
  },
  error: '',
  pending: false,
});

async function onLoginClick() {
  try {
    form.error = '';
    form.pending = true;

    const data = await createToken(
      config.public.CLIENT_ID,
      config.public.CLIENT_SECRET,
      form.data.email,
      form.data.password,
    );
    if (!data) {
      form.error = 'Nous ne pouvons pas vous identifier';
      return;
    }
    setToken(data);
    await getUserData();
    navigateTo('/mon-tableau-de-bord');
  } catch {
    form.error = 'Nous ne pouvons pas vous identifier';
  } finally {
    form.pending = false;
  }
}
</script>

<template>
  <div>
    <div
      class="h-screen bg-right-bottom bg-cover bg-[url('/assets/img/background-connexion.png')] flex flex-col justify-center gap-5 px-7"
    >
      <div
        class="bg-white rounded-t-3xl rounded-bl-3xl p-5 flex flex-col justify-center items-center shadow-2xl w-full max-w-lg mx-auto lg:ml-28"
      >
        <div>
          <img class="h-auto w-32 m-auto" src="/assets/img/logo-BR-groupe.webp" alt="Logo de la société" />
          <p class="font-bold text-xs text-darkblue_brs text-center">Vous roulez, vous branchez,</p>
          <p class="font-bold text-xs text-lightblue_brs text-center">le reste on s'en charge!</p>
        </div>
        <h1 class="text-darkblue_brs font-bold text-2xl m-7">Bienvenue</h1>
        <form class="flex flex-col justify-center items-center gap-3" @submit.prevent="onLoginClick">
          <p
            v-if="form.error"
            class="mb-3 px-3 py-1.5 w-full border rounded border-red-400 text-sm text-center text-red-400"
          >
            {{ form.error }}
          </p>
          <div class="mb-3">
            <input
              id="email"
              v-model="form.data.email"
              type="email"
              placeholder="Email"
              class="border-b-2 border-b-gray-600 placeholder-gray-600"
              required
            />
          </div>
          <div class="mb-3">
            <input
              id="password"
              v-model="form.data.password"
              type="password"
              placeholder="Mot de passe"
              class="border-b-2 border-b-gray-600 placeholder-gray-600"
              required
            />
          </div>
          <button
            type="submit"
            :disabled="form.pending"
            class="border-2 border-darkblue_brs text-darkblue_brs rounded-md px-3 py-2"
          >
            Connexion
          </button>
          <NuxtLink
            to="/motdepasse-oublie"
            class="border-2 border-lightblue_brs text-lightblue_brs rounded-md px-3 py-2"
          >
            Mot de passe oublié
          </NuxtLink>
        </form>
      </div>
      <div class="bg-white rounded-t-3xl rounded-bl-3xl p-3 shadow-2xl w-full max-w-lg mx-auto lg:ml-28">
        <p class="font-bold text-center">Borne Recharge Service / BR Park</p>
        <p class="font-bold text-center">18 bis rue Molitor - 75016 Paris</p>
      </div>
    </div>
  </div>
</template>
